import React, { Component } from 'react'
import { Select } from 'antd'
import * as PhosphorIcons from '@phosphor-icons/react'
import { icons } from '@phosphor-icons/core'

import I18n from '../../../I18n/I18n'

// Styles
const Styles = {
  select: {
    width: '100%',
    marginTop: '4px'
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  }
}

class IconSelector extends Component {
  constructor (props) {
    super(props)

    //  Generate pulldown options
    this.iconOptions = [
      {
        label: I18n.t('IconSelector.nullSelection'),
        value: null
      },
      ...icons.map((icon) => {
        const iconName = icon.pascal_name
          const IconComponent = PhosphorIcons[iconName]
          return {
            label: (
              <div style={Styles.option}>
                <IconComponent size={20} />
                {this.convertToCamelCaseWithSpace(iconName)}
              </div>
            ),
            value: this.convertToKebabCase(iconName)
          }
        })
    ]

    this.state = {
      selectedIcon: props.value || null
    }
  }

  handleIconChange = (value) => {
    this.setState({ selectedIcon: value })

    // Callback to update the parent state
    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }

  convertToCamelCaseWithSpace = (input) => {
    return input.replace(/([A-Z])/g, ' $1').replace(/^ /, '')
  }

  convertToKebabCase = (input) => {
    return input
      .replace(/([A-Z])/g, '-$1')
      .toLowerCase()
      .replace(/^-/, '')
  }

  render () {
    const { selectedIcon } = this.state

    return (
      <Select
        style={Styles.select}
        placeholder={I18n.t('IconSelector.selectIcon')}
        value={selectedIcon}
        onChange={this.handleIconChange}
        options={this.iconOptions}
        allowClear={true}
        showSearch
        filterOption={(input, option) =>
          option.value === null
            ? false
            : option.value.toLowerCase().includes(input.toLowerCase())
        }
      />
    )
  }
}

export default IconSelector
