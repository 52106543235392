import React, { Component } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { App, Modal, Upload, Button } from 'antd'

import I18n from '../../../I18n/I18n'
import AppConfig from '../../../Config/AppConfig'
import {
  getToken,
  getMediaList,
  deleteMedium
} from '../../../Services/ServerAccessService'

import Log from '../../../Utils/Log'
const log = new Log('Components/Dashboard/Generic/MediaLibrary')

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

function withHooks (WrappedComponent) {
  return function (props) {
    const app = App.useApp()

    return <WrappedComponent app={app} {...props} />
  }
}

class MediaLibrary extends Component {
  static defaultProps = {
    used: [],
    purpose: 'LIBRARY',
    mediaType: 'images',
    syncMedia: null
  }

  constructor (props) {
    super(props)

    this.state = {
      mediaURL: null,
      previewOpen: '',
      previewURL: '',
      previewTitle: '',
      fileList: [],
      supportsImages: props.mediaType === 'images',
      supportsAudios: props.mediaType === 'audios',
      supportsVideos: props.mediaType === 'videos'
    }

    log.debug(
      `Media library with purpose ${props.purpose} supports images ${this.state.supportsImages}, supports audios ${this.state.supportsAudios}, supports videos ${this.state.supportsVideos}`
    )
  }

  async componentDidMount () {
    await this.getMedia()
  }

  // Get data from server
  async getMedia () {
    const { purpose } = this.props
    const { supportsImages, supportsAudios, supportsVideos } = this.state

    const result = await getMediaList(purpose)

    log.debug('Get result:', result)

    const fileList = []

    const mediums = result.mediums.filter(
      (medium) =>
        (medium.type === 'IMAGE' && supportsImages) ||
        (medium.type === 'AUDIO' && supportsAudios) ||
        (medium.type === 'VIDEO' && supportsVideos)
    )

    for (const medium of mediums) {
      fileList.push({
        uid: medium.uid,
        name: medium.name,
        status: 'done',
        url: result.mediaURL + medium.uid
      })
    }

    const stateUpdate = {
      mediaURL: result.mediaURL,
      fileList
    }
    this.setState(stateUpdate)
    this.syncWithParents(stateUpdate)
  }

  syncWithParents (update) {
    const { syncMedia } = this.props
    const { mediaURL, fileList } = update

    if (syncMedia !== null) {
      const mediums = []

      for (const item of fileList) {
        if (item.status === 'done') {
          mediums.push({
            uid: item.uid,
            name: item.name,
            mimeType: item.mimeType
          })
        }
      }

      syncMedia({
        mediaURL,
        mediums
      })
    }
  }

  async deleteMedium (mediumId) {
    const result = await deleteMedium(mediumId)

    log.debug('Delete result:', result)

    if (result === null) {
      // Unknown error
      this.props.app.message.error(I18n.t('Common.remoteSyncError'))
    } else if (result === undefined) {
      // Inconsistent
      this.props.app.message.warning(I18n.t('Common.remoteSyncInconsistent'))

      await this.getMedia()
    } else {
      // Success
      this.props.app.message.success(I18n.t('Common.remoteSyncSuccess'))
    }
  }

  // Preview
  handleOpenPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    this.setState({
      previewOpen: true,
      previewURL: file.url || file.preview,
      previewTitle: file.uid ? `ID: ${file.uid} (${file.name})` : file.name
    })
  }

  handleCancelPreview = () => {
    const audioElements = document.querySelectorAll('.media-preview')
    audioElements.forEach((audio) => {
      audio.pause()
      audio.currentTime = 0
    })
    this.setState({ previewOpen: false })
  }

  // File upload
  handleChange = (info) => {
    const { mediaURL } = this.state
    const { message } = this.props.app

    const newFileList = info.fileList

    if (info.file.status === 'done') {
      log.debug('success')
      message.success(
        I18n.t('MediaLibrary.uploadSuccessful', { file: info.file.name })
      )

      for (const item of newFileList) {
        if (item.uid === info.file.uid) {
          item.uid = info.file.response
          item.url = mediaURL + info.file.response
          break
        }
      }
    } else if (info.file.status === 'error') {
      log.debug('error')
      message.error(
        I18n.t('MediaLibrary.uploadFailed', { file: info.file.name })
      )
    }

    const stateUpdate = { mediaURL, fileList: newFileList }
    this.setState(stateUpdate)
    this.syncWithParents(stateUpdate)
  }

  // File remove
  handleRemove = async (info) => {
    const { used } = this.props
    const { message } = this.props.app

    if (info.percent === 0) {
      return true
    }

    if (used.includes(info.uid)) {
      message.warning(I18n.t('MediaLibrary.inUseError', { id: info.uid }))
      return false
    }

    await this.deleteMedium(info.uid)
  }

  // Pre-upload check
  beforeUpload = (file) => {
    log.debug(`Checking file for upload compatibility: ${file.type}`)

    const { message } = this.props.app
    const { supportsImages, supportsAudios, supportsVideos } = this.state

    let supported = true

    const isSupportedImageFormat =
      file.type === 'image/jpeg' || file.type === 'image/png'
    const isSupportedAudioFormat =
      file.type === 'audio/aac' ||
      file.type === 'audio/x-aac' ||
      file.type === 'audio/x-m4a'
    const isSupportedVideoFormat =
      file.type === 'video/mp4' || file.type === 'video/x-m4v'

    if (supportsImages && !isSupportedImageFormat) {
      supported = false
      message.error(I18n.t('MediaLibrary.imageFormatError'))
    } else if (supportsAudios && !isSupportedAudioFormat) {
      supported = false
      message.error(I18n.t('MediaLibrary.audioFormatError'))
    } else if (supportsVideos && !isSupportedVideoFormat) {
      supported = false
      message.error(I18n.t('MediaLibrary.videoFormatError'))
    }

    if (!supported) {
      return supported
    }

    const isLt5M = file.size / 1024 / 1024 < 5
    const isLt50M = file.size / 1024 / 1024 < 50
    const isLt100M = file.size / 1024 / 1024 < 100

    if (supportsImages && !isLt5M) {
      supported = false
      message.error(I18n.t('MediaLibrary.imageSizeError'))
    } else if (supportsAudios && !isLt50M) {
      supported = false
      message.error(I18n.t('MediaLibrary.audioSizeError'))
    } else if (supportsVideos && !isLt100M) {
      supported = false
      message.error(I18n.t('MediaLibrary.videoSizeError'))
    }

    return supported
  }

  render () {
    const {
      mediaURL,
      previewOpen,
      previewURL,
      previewTitle,
      fileList,
      supportsImages,
      supportsAudios,
      supportsVideos
    } = this.state
    const { purpose } = this.props

    if (mediaURL != null) {
      return (
        <>
          <Upload
            multiple
            action={
              AppConfig.serverAccess.restURL + 'v03/media/upload/' + purpose
            }
            headers={{
              authorization: getToken()
            }}
            listType={
              supportsImages
                ? purpose === 'AVATARS'
                  ? 'picture-circle'
                  : 'picture-card'
                : 'text'
            }
            fileList={fileList}
            showUploadList={{
              showDownloadIcon: true,
              showRemoveIcon: true
            }}
            onPreview={this.handleOpenPreview}
            onChange={this.handleChange}
            onRemove={this.handleRemove}
            beforeUpload={this.beforeUpload}
          >
            {supportsImages ? (
              <button
                style={{
                  border: 0,
                  background: 'none'
                }}
                type='button'
              >
                <PlusOutlined />
                <div
                  style={{
                    marginTop: 8
                  }}
                >
                  {I18n.t('MediaLibrary.upload')}
                </div>
              </button>
            ) : (
              <Button icon={<PlusOutlined />}>
                {I18n.t('MediaLibrary.upload')}
              </Button>
            )}
          </Upload>
          <Modal
            open={previewOpen}
            title={previewTitle}
            footer={null}
            onCancel={this.handleCancelPreview}
          >
            {supportsImages ? (
              <img
                style={{
                  width: '100%'
                }}
                alt=''
                src={previewURL}
              />
            ) : null}
            {supportsAudios ? (
              <audio
                controls
                class='media-preview'
                style={{ minWidth: '200px', width: '100%' }}
                src={previewURL}
              />
            ) : null}
            {supportsVideos ? (
              <video
                controls
                class='media-preview'
                style={{ minWidth: '200px', width: '100%' }}
                src={previewURL}
              />
            ) : null}
          </Modal>
        </>
      )
    } else {
      return null
    }
  }
}

export default withHooks(MediaLibrary)
