import React, { Component } from 'react'
import { connect } from 'react-redux'
import { App, Layout, Typography, Row, Space, Button, Divider } from 'antd'

import I18n from '../../../I18n/I18n'
import GUIActions from '../../../Redux/GUIRedux'
import {
  getTeamOnboarding,
  updateTeamOnboarding
} from '../../../Services/ServerAccessService'
import MLInput from '../Generic/MLInput'

import Log from '../../../Utils/Log'
const log = new Log('Components/Dashboard/Content/Onboarding')

const { Content } = Layout
const { Title, Text } = Typography

// Styles
const titleContainerStyle = {
  display: 'flex',
  marginBottom: -14
}

const titleStyle = { flex: 1 }

const saveButtonStyle = {
  marginTop: 30,
  marginLeft: 10,
  paddingLeft: 30,
  paddingRight: 30,
  flex: 0
}

function withHooks (WrappedComponent) {
  return function (props) {
    const app = App.useApp()

    return <WrappedComponent app={app} {...props} />
  }
}

class Onboarding extends Component {
  constructor (props) {
    super(props)

    // Only default values must be defined (with null)
    this.state = {
      _lastUpdated: 0,
      appInfo: null,
      textWelcome: null,
      textNotificationInformation: null
    }
  }

  async componentDidMount () {
    await this.getOnboarding()
  }

  setState (newState, newSaved = undefined) {
    super.setState(newState)

    const { saved, setSaved } = this.props

    if (newSaved !== undefined && saved !== newSaved) {
      setSaved(newSaved)
    }
  }

  // Get data from server
  async getOnboarding () {
    const result = await getTeamOnboarding()

    log.debug('Get result:', result)

    this.setState(
      {
        ...result
      },
      true
    )
  }

  // Update data on server
  async updateOnboarding () {
    const { _lastUpdated, appInfo, textWelcome, textNotificationInformation } =
      this.state

    // Update object
    const update = {
      _lastUpdated,
      appInfo,
      textWelcome,
      textNotificationInformation
    }
    log.debug('Update object:', update)

    const result = await updateTeamOnboarding(update)

    log.debug('Update result:', result)

    if (result === null) {
      // Unknown error
      this.props.app.message.error(I18n.t('Common.remoteSyncError'))
    } else if (result === undefined) {
      // Inconsistent
      this.props.app.message.warning(I18n.t('Common.remoteSyncInconsistent'))

      await this.getOnboarding()
    } else {
      // Success
      this.props.app.message.success(I18n.t('Common.remoteSyncSaved'))

      this.setState({ ...result }, true)
    }
  }

  render () {
    const { saved } = this.props
    const {
      _lastUpdated,
      locales,
      appInfo,
      textNotificationInformation,
      textWelcome
    } = this.state

    if (_lastUpdated > 0) {
      return (
        <Content>
          <Row style={titleContainerStyle}>
            <Title style={titleStyle} level={2}>
              {I18n.t('Onboarding.title')}
            </Title>
            <Button
              type={saved ? 'default' : 'primary'}
              style={saveButtonStyle}
              onClick={() => {
                this.updateOnboarding()
              }}
            >
              {I18n.t('Common.save')}
            </Button>
          </Row>
          <Divider />
          <Title level={3}>{I18n.t('Onboarding.configureOnboarding')}</Title>
          <Title level={5}>{I18n.t('Onboarding.onboardingTexts')}</Title>
          <Space
            direction='vertical'
            size='middle'
            style={{
              display: 'flex'
            }}
          >
            <Row>
              <Text>{I18n.t('Onboarding.appInfoLabel')}</Text>

              <MLInput
                value={appInfo}
                placeholderPattern={'Onboarding.appInfoPlaceholder'}
                locales={locales}
                onChange={(values) => {
                  this.setState({ appInfo: values }, false)
                }}
              />
            </Row>
            <Row>
              <Text>
                {I18n.t('Onboarding.textNotificationInformationLabel')}
              </Text>
              <MLInput
                value={textNotificationInformation}
                placeholderPattern={
                  'Onboarding.textNotificationInformationPlaceholder'
                }
                locales={locales}
                onChange={(values) => {
                  this.setState({ textNotificationInformation: values }, false)
                }}
              />
            </Row>
            <Row>
              <Text>{I18n.t('Onboarding.textWelcomeLabel')}</Text>
              <MLInput
                value={textWelcome}
                placeholderPattern={'Onboarding.textWelcomePlaceholder'}
                locales={locales}
                onChange={(values) => {
                  this.setState({ textWelcome: values }, false)
                }}
              />
            </Row>
          </Space>
        </Content>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = (state) => ({
  saved: state.guiState.saved
})

const mapStateToDispatch = (dispatch) => ({
  setSaved: (saved) => {
    dispatch(GUIActions.setSaved(saved))
  }
})

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(withHooks(Onboarding))
