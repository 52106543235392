import React, { Component } from 'react'
import { Typography, Checkbox } from 'antd'
import { DndContext, closestCenter } from '@dnd-kit/core'
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
  useSortable
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import AppConfig from '../../../Config/AppConfig'

const { Text } = Typography

const SortableItem = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    marginBottom: 8,
    background: '#fff',
    padding: '8px 16px',
    border: '1px solid #d9d9d9',
    borderRadius: 4,
    cursor: 'grab'
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  )
}

class LanguageSelector extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedLanguages: props.initialLocales || []
    }
    this.languageNames = new Intl.DisplayNames(AppConfig.project.languages, {
      type: 'language'
    })
    this.regionNames = new Intl.DisplayNames(AppConfig.project.languages, {
      type: 'region'
    })
  }

  getLocaleName = (locale) => {
    const localeParts = locale.replace('-', '_').split('_')

    const codePoints = localeParts[1]
      .toUpperCase()
      .split('')
      .map((char) => 127397 + char.charCodeAt(0))

    return (
      this.languageNames.of(localeParts[0]) +
      ' ' +
      String.fromCodePoint(...codePoints)
    )
  }

  onCheckboxChange = (lang) => {
    this.setState((prevState) => {
      const selectedLanguages = prevState.selectedLanguages.includes(lang)
        ? prevState.selectedLanguages.filter((l) => l !== lang)
        : [...prevState.selectedLanguages, lang]

      if (selectedLanguages.length === 0) {
        return { selectedLanguages: prevState.selectedLanguages }
      } else {
        this.props.onChange(selectedLanguages)
        return { selectedLanguages }
      }
    })
  }

  handleDragEnd = (event) => {
    const { active, over } = event

    if (!over || active.id === over.id) return

    this.setState((prevState) => {
      const oldIndex = prevState.selectedLanguages.indexOf(active.id)
      const newIndex = prevState.selectedLanguages.indexOf(over.id)

      const reorderedLanguages = arrayMove(
        prevState.selectedLanguages,
        oldIndex,
        newIndex
      )
      this.props.onChange(reorderedLanguages)
      return { selectedLanguages: reorderedLanguages }
    })
  }

  render () {
    const { locales, selectLanguages, arrangeLanguages } = this.props
    const { selectedLanguages } = this.state

    const gridStyle = {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
      gap: '8px'
    }

    return (
      <div
        style={{
          display: 'block',
          width: '100%'
        }}
      >
        <Text style={{ display: 'block', marginBottom: 8 }}>
          {selectLanguages}
        </Text>
        <div style={gridStyle}>
          {locales.map((lang) => (
            <Checkbox
              key={lang}
              checked={selectedLanguages.includes(lang)}
              onChange={() => this.onCheckboxChange(lang)}
            >
              {this.getLocaleName(lang) || lang}
            </Checkbox>
          ))}
        </div>
        <Text style={{ display: 'block', marginTop: 16, marginBottom: 8 }}>
          {arrangeLanguages}
        </Text>
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={this.handleDragEnd}
        >
          <SortableContext
            items={selectedLanguages}
            strategy={verticalListSortingStrategy}
          >
            {selectedLanguages.map((lang) => (
              <SortableItem key={lang} id={lang}>
                {this.getLocaleName(lang) || lang}
              </SortableItem>
            ))}
          </SortableContext>
        </DndContext>
      </div>
    )
  }
}

export default LanguageSelector
