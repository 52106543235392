import React, { Component } from 'react'
import { Layout, Divider, Typography } from 'antd'

import I18n from '../../../I18n/I18n'
import GlobalMediaLibrary from '../Generic/MediaLibrary'

// import Log from '../../../Utils/Log'
// const log = new Log('Home')

const { Content } = Layout
const { Title, Text } = Typography

// Styles
const invisibleSpace = {
  padding: 10
}

class MediaLibrary extends Component {
  render () {
    return (
      <Content>
        <Title level={2}>{I18n.t('MediaLibrary.title')}</Title>
        <Divider />
        {I18n.t('MediaLibrary.infoText')}
        <Title level={3}>{I18n.t('MediaLibrary.images')}</Title>
        <Text>{I18n.t('MediaLibrary.imagesInfo')}</Text>
        <div style={invisibleSpace} />
        <GlobalMediaLibrary purpose={'LIBRARY'} mediaType={'images'} />
        <Title level={3}>{I18n.t('MediaLibrary.audios')}</Title>
        <Text>{I18n.t('MediaLibrary.audiosInfo')}</Text>
        <div style={invisibleSpace} />
        <GlobalMediaLibrary purpose={'LIBRARY'} mediaType={'audios'} />
        <Title level={3}>{I18n.t('MediaLibrary.videos')}</Title>
        <Text>{I18n.t('MediaLibrary.videosInfo')}</Text>
        <div style={invisibleSpace} />
        <GlobalMediaLibrary purpose={'LIBRARY'} mediaType={'videos'} />
      </Content>
    )
  }
}

export default MediaLibrary
