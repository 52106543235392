import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  App,
  Layout,
  Space,
  Row,
  Input,
  Button,
  Checkbox,
  Typography,
  Tabs,
  Divider
} from 'antd'
import {
  LeftOutlined,
  RightOutlined,
  PlusOutlined,
  ContactsOutlined
} from '@ant-design/icons'

import I18n from '../../../I18n/I18n'
import GUIActions from '../../../Redux/GUIRedux'
import {
  getTeamScreens,
  updateTeamScreens
} from '../../../Services/ServerAccessService'
import { isBlank } from '../../../Utils/Helpers'
import MLInput from '../Generic/MLInput'
import IconSelector from '../Generic/IconSelector'

import Log from '../../../Utils/Log'
const log = new Log('Components/Dashboard/Content/Screens')

const { Content } = Layout
const { Title, Text } = Typography
const { TextArea } = Input

// Styles
const titleContainerStyle = {
  display: 'flex',
  marginBottom: -14
}

const titleStyle = { flex: 1 }

const saveButtonStyle = {
  marginTop: 30,
  marginLeft: 10,
  paddingLeft: 30,
  paddingRight: 30,
  flex: 0
}

class Screen extends Component {
  render () {
    const { index, screen, locales, updateScreen, updateScreenContents } =
      this.props

    return (
      <Space
        direction='vertical'
        size='middle'
        style={{
          display: 'flex'
        }}
      >
        <Row>
          <Text>{I18n.t('Screens.idLabel')}</Text>
          <Input
            placeholder={I18n.t('Screens.idPlaceholder')}
            defaultValue={screen.screenId}
            prefix={<ContactsOutlined />}
            onBlur={(e) => {
              updateScreen(index, { screenId: e.target.value })
            }}
          />
        </Row>
        <Row>
          <Text>{I18n.t('Screens.iconLabel')}</Text>
          <IconSelector
            value={screen.icon}
            onChange={(value) => {
              updateScreen(index, { icon: value })
            }}
          />
        </Row>
        <Row>
          <Text>{I18n.t('Screens.titleLabel')}</Text>
          <MLInput
            value={screen.title}
            placeholderPattern={'Screens.titlePlaceholder'}
            locales={locales}
            onChange={(values) => {
              updateScreen(index, { title: values })
            }}
          />
        </Row>
        <Row>
          <Checkbox
            checked={screen.visibleInMenu}
            onChange={(e) => {
              updateScreen(index, { visibleInMenu: e.target.checked })
            }}
          >
            {I18n.t('Screens.visibleInMenuCheckbox')}
          </Checkbox>
        </Row>
        <Row>
          <Text>{I18n.t('Screens.contentsLabel')}</Text>
          <TextArea
            placeholder={I18n.t('Screens.contentsPlaceholder')}
            defaultValue={JSON.stringify(screen.contents, null, 2)}
            prefix={<ContactsOutlined />}
            onBlur={(e) => {
              updateScreenContents(index, e.target.value)
            }}
            autoSize={{
              minRows: 4
            }}
          />
        </Row>
      </Space>
    )
  }
}

function withHooks (WrappedComponent) {
  return function (props) {
    const app = App.useApp()

    return <WrappedComponent app={app} {...props} />
  }
}

class Screens extends Component {
  constructor (props) {
    super(props)

    this.updateScreen = this.updateScreen.bind(this)
    this.updateScreenContents = this.updateScreenContents.bind(this)

    this.increasingRandom = 0

    // Only default values must be defined (with null)
    this.state = {
      _lastUpdated: 0,
      screens: [],
      activeTab: ''
    }
  }

  async componentDidMount () {
    await this.getScreens()
  }

  setState (newState, newSaved = undefined) {
    super.setState(newState)

    const { saved, setSaved } = this.props

    if (newSaved !== undefined && saved !== newSaved) {
      setSaved(newSaved)
    }
  }

  // Get data from server
  async getScreens () {
    const result = await getTeamScreens()

    log.debug('Get result:', result)

    const newScreens = []

    for (const screen of result.screens) {
      newScreens.push({ ...screen, random: this.increasingRandom++ })
    }

    this.setState(
      {
        ...result,
        screens: newScreens,
        activeTab:
          result.screens.length > 0 ? `screenTab_0_${newScreens[0].random}` : ''
      },
      true
    )
  }

  // Update data on server
  async updateScreens () {
    const { _lastUpdated, screens } = this.state

    // Update object
    const update = {
      _lastUpdated,
      screens: screens.map((screen) => {
        const newScreen = { ...screen }
        delete newScreen.random
        return newScreen
      })
    }
    log.debug('Update object:', update)

    const result = await updateTeamScreens(update)

    log.debug('Update result:', result)

    if (result === null) {
      // Unknown error
      this.props.app.message.error(I18n.t('Common.remoteSyncError'))
    } else if (result === undefined) {
      // Inconsistent
      this.props.app.message.warning(I18n.t('Common.remoteSyncInconsistent'))

      await this.getScreens()
    } else {
      // Success
      this.props.app.message.success(I18n.t('Common.remoteSyncSaved'))

      const newScreens = []

      for (const screen of result.screens) {
        newScreens.push({ ...screen, random: this.increasingRandom++ })
      }

      this.setState(
        {
          ...result,
          screens: newScreens,
          activeTab:
            result.screens.length > 0
              ? `screenTab_0_${newScreens[0].random}`
              : ''
        },
        true
      )
    }
  }

  addScreenTab () {
    const { screens } = this.state

    const newScreen = {
      random: this.increasingRandom++,
      screenId: 'screen-' + this.increasingRandom,
      icon: null,
      title: {
        values: {}
      },
      visibleInMenu: false,
      contents: []
    }
    const newScreens = [...screens, newScreen]

    const newActiveTab = `screenTab_${newScreens.length - 1}_${
      newScreen.random
    }`

    this.setState(
      {
        screens: newScreens,
        activeTab: newActiveTab
      },
      false
    )
  }

  removeScreenTab (targetTab) {
    const { screens, activeTab } = this.state

    const removeIndex = parseInt(targetTab.split('_')[1])
    const currentIndex = parseInt(activeTab.split('_')[1])

    const newScreens = [...screens]
    newScreens.splice(removeIndex, 1)

    let newIndex = -1
    if (currentIndex > newScreens.length - 1) {
      newIndex = newScreens.length - 1
    } else {
      newIndex = currentIndex
    }

    this.setState(
      {
        screens: newScreens,
        activeTab:
          newIndex > -1
            ? `screenTab_${newIndex}_${newScreens[newIndex].random}`
            : ''
      },
      false
    )
  }

  moveScreenTabPossible (movement) {
    const { activeTab, screens } = this.state

    if (activeTab === '') {
      return false
    }

    const currentIndex = parseInt(activeTab.split('_')[1])
    const newIndex = currentIndex + movement

    if (newIndex < 0 || newIndex > screens.length - 1) {
      return false
    }

    return true
  }

  moveScreenTab (movement) {
    const { activeTab, screens } = this.state

    if (activeTab === '') {
      return
    }

    const currentIndex = parseInt(activeTab.split('_')[1])
    const newIndex = currentIndex + movement

    if (newIndex < 0 || newIndex > screens.length - 1) {
      return
    }

    const newScreens = [...screens]
    const element = newScreens[currentIndex]
    newScreens.splice(currentIndex, 1)
    newScreens.splice(newIndex, 0, element)

    this.setState(
      {
        screens: newScreens,
        activeTab:
          newIndex > -1
            ? `screenTab_${newIndex}_${newScreens[newIndex].random}`
            : ''
      },
      false
    )
  }

  handleTabChange = (activeTab) => {
    log.debug('Switched to tab:', activeTab)
    this.setState({ activeTab })
  }

  handleTabEdit = (targetTab, action) => {
    if (action === 'add') {
      this.addScreenTab()
    } else {
      this.removeScreenTab(targetTab)
    }
  }

  updateScreen (index, value) {
    const { screens } = this.state

    const newScreens = [...screens]
    newScreens[index] = { ...newScreens[index], ...value }

    this.setState({ screens: newScreens }, false)
  }

  updateScreenContents (index, value) {
    const { message } = this.props.app
    const { screens } = this.state

    const newScreens = [...screens]
    try {
      newScreens[index].contents = JSON.parse(value)
    } catch (e) {
      message.error(I18n.t('Screens.updateScreenContentsFailed'))
      return
    }

    this.setState({ screens: newScreens }, false)
  }

  render () {
    const { saved } = this.props
    const { _lastUpdated, locales, screens, activeTab } = this.state

    const hideAdd = screens.length >= 25

    if (_lastUpdated > 0) {
      return (
        <Content>
          <Row style={titleContainerStyle}>
            <Title style={titleStyle} level={2}>
              {I18n.t('Screens.title')}
            </Title>
            <Button
              type={saved ? 'default' : 'primary'}
              style={saveButtonStyle}
              onClick={() => {
                this.updateScreens()
              }}
            >
              {I18n.t('Common.save')}
            </Button>
          </Row>
          <Divider />
          <Title level={3}>{I18n.t('Screens.configureScreens')}</Title>
          <Tabs
            type='editable-card'
            tabPosition='top'
            onChange={this.handleTabChange}
            onEdit={this.handleTabEdit}
            hideAdd={true}
            activeKey={activeTab}
            // renderTabBar={(props, DefaultTabBar) => (
            //   <div>
            //     <DefaultTabBar {...props} />
            //   </div>
            // )}
            tabBarExtraContent={{
              left: (
                <Button
                  type='link'
                  title={I18n.t('Screens.moveLeft')}
                  disabled={!this.moveScreenTabPossible(-1)}
                  onClick={() => this.moveScreenTab(-1)}
                >
                  <LeftOutlined />
                </Button>
              ),
              right: (
                <div>
                  <Button
                    type='link'
                    title={I18n.t('Screens.add')}
                    disabled={hideAdd}
                    onClick={() => this.addScreenTab()}
                  >
                    <PlusOutlined />
                  </Button>
                  <Button
                    type='link'
                    title={I18n.t('Screens.moveRight')}
                    disabled={!this.moveScreenTabPossible(1)}
                    onClick={() => this.moveScreenTab(1)}
                  >
                    <RightOutlined />
                  </Button>
                </div>
              )
            }}
            items={screens.map((screen, index) => ({
              key: `screenTab_${index}_${screen.random}`,
              label:
                I18n.t('Screens.screen') +
                ' ' +
                (isBlank(screen.screenId)
                  ? index + 1
                  : '"' + screen.screenId + '"'),
              children: (
                <Screen
                  index={index}
                  screen={screen}
                  locales={locales}
                  updateScreen={this.updateScreen}
                  updateScreenContents={this.updateScreenContents}
                />
              )
            }))}
          />
        </Content>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = (state) => ({
  saved: state.guiState.saved
})

const mapStateToDispatch = (dispatch) => ({
  setSaved: (saved) => {
    dispatch(GUIActions.setSaved(saved))
  }
})

export default connect(mapStateToProps, mapStateToDispatch)(withHooks(Screens))
