import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  App,
  Layout,
  Typography,
  Input,
  Row,
  Space,
  Button,
  Select,
  Divider
} from 'antd'
import { ContactsOutlined } from '@ant-design/icons'

import I18n from '../../../I18n/I18n'
import GUIActions from '../../../Redux/GUIRedux'
import { objectIdString, objectIdEquals } from '../../../Utils/ObjectIdHelper'
import {
  getTeamBasics,
  updateTeamBasics
} from '../../../Services/ServerAccessService'
import MediaLibrary from '../Generic/MediaLibrary'
import LanguageSelector from '../Generic/LanguageSelector'

import Log from '../../../Utils/Log'
const log = new Log('Components/Dashboard/Content/Basics')

const { Content } = Layout
const { Title, Text } = Typography
const { Option } = Select

// Styles
const titleContainerStyle = {
  display: 'flex',
  marginBottom: -14
}

const titleStyle = { flex: 1 }

const saveButtonStyle = {
  marginTop: 30,
  marginLeft: 10,
  paddingLeft: 30,
  paddingRight: 30,
  flex: 0
}

const imageSelectStyle = { width: '100%', height: 120 }

const imageOptionStyle = { justifyContent: 'center', verticalAlign: 'middle' }

const imageOptionStyleImageWrapper = {
  height: 100,
  width: 100,
  verticalAlign: 'middle',
  objectFit: 'contain',
  display: 'inline-block'
}

const imageOptionStyleImage = {
  width: '100%',
  height: '100%',
  verticalAlign: 'middle',
  display: 'block',
  objectFit: 'contain'
}

function withHooks (WrappedComponent) {
  return function (props) {
    const app = App.useApp()

    return <WrappedComponent app={app} {...props} />
  }
}

class Basics extends Component {
  constructor (props) {
    super(props)

    this.syncMedia = this.syncMedia.bind(this)

    // Only default values must be defined (with null)
    this.state = {
      _lastUpdated: 0,
      appName: null,
      appLogo: null,
      interventionSelection: null,
      locales: [],
      savedUsed: [],
      mediaURL: null,
      mediums: []
    }
  }

  async componentDidMount () {
    await this.getBasics()
  }

  setState (newState, newSaved = undefined) {
    super.setState(newState)

    const { saved, setSaved } = this.props

    if (newSaved !== undefined && saved !== newSaved) {
      setSaved(newSaved)
    }
  }

  // Get data from server
  async getBasics () {
    const result = await getTeamBasics()

    log.debug('Get result:', result)

    // Provide initial options
    const interventionSelectionOptions = result.interventionOptions.map(
      (option) => ({
        label: option.interventionName,
        value: objectIdString(option.objectId),
        objectId: option.objectId
      })
    )

    const interventionSelection =
      interventionSelectionOptions.filter((interventionSelectionOption) =>
        objectIdEquals(
          interventionSelectionOption.objectId,
          result.intervention?.objectId
        )
      )[0] || null

    this.setState(
      {
        ...result,
        interventionSelectionOptions,
        interventionSelection,
        savedUsed: [result.appLogo]
      },
      true
    )
  }

  // Update data on server
  async updateBasics () {
    const { _lastUpdated, appName, appLogo, interventionSelection, locales } =
      this.state

    // Update object
    const update = {
      _lastUpdated,
      appName,
      appLogo,
      intervention: interventionSelection?.objectId || null,
      locales
    }
    log.debug('Update object:', update)

    const result = await updateTeamBasics(update)

    log.debug('Update result:', result)

    if (result === null) {
      // Unknown error
      this.props.app.message.error(I18n.t('Common.remoteSyncError'))
    } else if (result === undefined) {
      // Inconsistent
      this.props.app.message.warning(I18n.t('Common.remoteSyncInconsistent'))

      await this.getBasics()
    } else {
      // Success
      this.props.app.message.success(I18n.t('Common.remoteSyncSaved'))

      this.setState({ ...result, savedUsed: [result.appLogo] }, true)
    }
  }

  // Sync media with library
  syncMedia (syncedMedia) {
    const { mediaURL, mediums } = syncedMedia

    this.setState({
      mediaURL,
      mediums
    })
  }

  renderImageSelectOptions (mediaURL, mediums) {
    const options = []

    for (const medium of mediums) {
      options.push(
        <Option key={medium.uid} value={medium.uid} style={imageOptionStyle}>
          <div style={imageOptionStyleImageWrapper}>
            <img
              src={mediaURL + medium.uid}
              alt=''
              style={imageOptionStyleImage}
            />
          </div>
          &nbsp;&nbsp; ID: <strong>{medium.uid}</strong> ({medium.name})
        </Option>
      )
    }

    return options
  }

  renderImageSelect () {
    const { appLogo, mediaURL, mediums } = this.state

    return (
      <Select
        style={imageSelectStyle}
        placeholder={I18n.t('Basics.chooseImagePlaceholder')}
        defaultValue={appLogo}
        onSelect={(key, imageSelection) => {
          this.setState({ appLogo: imageSelection.value }, false)
        }}
      >
        {this.renderImageSelectOptions(mediaURL, mediums)}
      </Select>
    )
  }

  render () {
    const { saved } = this.props
    const {
      _lastUpdated,
      appName,
      appLogo,
      interventionSelectionOptions,
      interventionSelection,
      availableLocales,
      locales,
      savedUsed
    } = this.state

    if (_lastUpdated > 0) {
      const used = [...savedUsed, appLogo]

      return (
        <Content>
          <Row style={titleContainerStyle}>
            <Title style={titleStyle} level={2}>
              {I18n.t('Basics.title')}
            </Title>
            <Button
              type={saved ? 'default' : 'primary'}
              style={saveButtonStyle}
              onClick={() => {
                this.updateBasics()
              }}
            >
              {I18n.t('Common.save')}
            </Button>
          </Row>
          <Divider />
          <Title level={3}>{I18n.t('Basics.configureApp')}</Title>
          <Title level={5}>{I18n.t('Basics.appSettings')}</Title>
          <Space
            direction='vertical'
            size='middle'
            style={{
              display: 'flex'
            }}
          >
            <Row>
              <Text>{I18n.t('Basics.appNameLabel')}</Text>
              <Input
                placeholder={I18n.t('Basics.appNamePlaceholder')}
                defaultValue={appName}
                prefix={<ContactsOutlined />}
                onBlur={(e) => {
                  this.setState({ appName: e.target.value }, false)
                }}
              />
            </Row>
            <Row>
              <Text style={{ textAlign: 'start' }}>
                {I18n.t('Basics.chooseImageLabel')}
              </Text>
              {this.renderImageSelect()}
            </Row>
            <Row>
              <LanguageSelector
                locales={availableLocales}
                initialLocales={locales}
                selectLanguages={I18n.t('Basics.selectLanguages')}
                arrangeLanguages={I18n.t('Basics.arrangeLanguages')}
                onChange={(selectedLanguages) => {
                  this.setState({ locales: selectedLanguages }, false)
                }}
              />
            </Row>
          </Space>
          <Title level={5}>{I18n.t('Basics.coachingSelection')}</Title>
          <Space
            direction='vertical'
            size='middle'
            style={{
              display: 'flex'
            }}
          >
            <Row
              style={{
                flexDirection: 'column'
              }}
            >
              <Text style={{ textAlign: 'start' }}>
                {I18n.t('Basics.chooseInterventionLabel')}
              </Text>
              <Select
                placeholder={I18n.t('Basics.chooseInterventionPlaceholder')}
                defaultValue={interventionSelection}
                options={interventionSelectionOptions}
                onSelect={(key, interventionSelection) => {
                  this.setState({ interventionSelection }, false)
                }}
                style={{ textAlign: 'start' }}
              />
            </Row>
          </Space>
          <Divider />
          <Title level={3}>{I18n.t('Basics.manageAppLogos')}</Title>
          <MediaLibrary
            used={used}
            purpose={'APP_LOGOS'}
            syncMedia={this.syncMedia}
          />
        </Content>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = (state) => ({
  saved: state.guiState.saved
})

const mapStateToDispatch = (dispatch) => ({
  setSaved: (saved) => {
    dispatch(GUIActions.setSaved(saved))
  }
})

export default connect(mapStateToProps, mapStateToDispatch)(withHooks(Basics))
