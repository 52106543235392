const preConfig = {
  environment: 'PUBLIC', // 'INTERNAL' or 'PUBLIC'
  localServer: false // true or false
}

const AppConfig = {
  // Configuration of the project
  project: {
    path: '/', // '/path-on-server/pcms/' or '/' for development
    languages: ['en-GB'],
    branding: 'mc' // 'pmcp', 'mc',
  },
  media: {
    introductionVideoURL: {
      pmcp: 'https://www.youtube-nocookie.com/embed/ikJvLTr6XTA',
      mc: 'https://www.youtube-nocookie.com/embed/Op9JGrJQsuA'
    }
  },
  logger: {
    // Levels: 'DEBUG', 'INFO', 'WARN', 'ERROR', 'OFF'
    defaultLevel: 'OFF', // 'OFF' to deactivate the WHOLE logger (also exceptions)
    loggerLevels: {
      App: 'DEBUG',
      'Services/ServerAccessService': 'DEBUG'
    }
  },
  modules:
    preConfig.environment === 'INTERNAL'
      ? {
          home: true,
          app: true,
          basics: true,
          coach: true,
          colors: true,
          onboarding: true,
          screens: true,
          mediaLibrary: true,
          coaching: true,
          export: true,
          transfer: false,
          account: true
        }
      : {
          home: true,
          app: true,
          basics: true,
          coach: true,
          colors: true,
          onboarding: true,
          screens: true,
          mediaLibrary: true,
          coaching: true,
          export: true,
          transfer: false,
          account: true
        },
  serverAccess: {
    restURL: preConfig.localServer
      ? 'http://localhost:8080/PMCPm-PCMS/api/'
      : 'https://cp15.pathmate.cloud/PMCPm-PCMS/api/',
    pmcpURL: preConfig.localServer
      ? 'http://localhost:8080/PMCP/admin'
      : 'https://cp15.pathmate.cloud/PMCP/admin',
    pmcpRestURL: preConfig.localServer
      ? 'http://localhost:8080/PMCP/api/'
      : 'https://cp15.pathmate.cloud/PMCP/api/',
    token: {
      iss: 'https://www.pathmate-technologies.com',
      sub: 'PMCPm PCMS'
    }
  }
}

export default AppConfig
