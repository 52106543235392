import React, { Component } from 'react'
import { Input, Tabs, Badge } from 'antd'

import AppConfig from '../../../Config/AppConfig'
import I18n from '../../../I18n/I18n'

// Styles
const Styles = {
  tabs: {
    width: '100%'
  },
  tabBar: {
    padding: '4px 0px 0px 8px',
    marginBottom: '0px'
  },
  input: {
    marginTop: '4px',
    width: '100%'
  }
}

class MultiLanguageInput extends Component {
  constructor (props) {
    super(props)
    this.state = {
      localValues: props.value?.values || {}
    }
    this.languageNames = new Intl.DisplayNames(AppConfig.project.languages, {
      type: 'language'
    })
    this.regionNames = new Intl.DisplayNames(AppConfig.project.languages, {
      type: 'region'
    })
  }

  getLocaleName = (locale) => {
    const localeParts = locale.replace('-', '_').split('_')

    const codePoints = localeParts[1]
      .toUpperCase()
      .split('')
      .map((char) => 127397 + char.charCodeAt(0))

    return (
      this.languageNames.of(localeParts[0]) +
      ' ' +
      String.fromCodePoint(...codePoints)
    )
  }

  handleInputChange = (lang, text) => {
    const updatedValues = {
      ...this.state.localValues,
      [lang]: text
    }
    this.setState({ localValues: updatedValues })

    // Callback to update the parent state
    if (this.props.onChange) {
      this.props.onChange({ values: updatedValues })
    }
  }

  isLanguageFilled = (lang) => {
    const { localValues } = this.state
    return !!localValues[lang]
  }

  render () {
    const { placeholderPattern, locales } = this.props
    const { localValues } = this.state

    return (
      <Tabs
        style={{ ...Styles.tabs }}
        tabBarStyle={{ ...Styles.tabBar }}
        defaultActiveKey={locales[0]}
        size='small'
        items={locales.map((lang, index) => {
          const langReadable = this.getLocaleName(lang)
          const isFilled = this.isLanguageFilled(lang)

          return {
            label: (
              <Badge color={isFilled ? 'green' : 'red'} text={langReadable} />
            ),
            children: (
              <Input
                style={Styles.input}
                placeholder={I18n.t(placeholderPattern, {
                  lang: langReadable
                })}
                value={localValues[lang] || ''}
                onChange={(e) => this.handleInputChange(lang, e.target.value)}
              />
            ),
            key: lang
          }
        })}
      />
    )
  }
}

export default MultiLanguageInput
