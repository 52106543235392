import AppConfig from '../Config/AppConfig'
import I18n from '../I18n/I18n'

const projectName = AppConfig.project.branding

const BrandStrings = {
  general: (queryString) => {
    return I18n.t(`branding.${projectName}.${queryString}`)
  }
}

export default BrandStrings
