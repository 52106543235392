import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Layout, Menu, Row, App } from 'antd'
import {
  LogoutOutlined,
  UserOutlined,
  FormatPainterOutlined,
  PlaySquareOutlined,
  MessageOutlined,
  SettingOutlined,
  HomeOutlined,
  ToolOutlined,
  PlayCircleOutlined,
  BookOutlined,
  ExportOutlined,
  CloudSyncOutlined,
  ProfileOutlined
} from '@ant-design/icons'
import { Link, Route, Redirect, Switch, matchPath } from 'react-router-dom'

import logo from '../../Images/Logo/logo_light.png'
import logoIcon from '../../Images/Logo/logo_light_icon.png'
import {
  Home,
  App as AppContent,
  Basics,
  Coach,
  Colors,
  Onboarding,
  Screens,
  MediaLibrary,
  Coaching,
  Account,
  Export,
  Transfer
} from './Content'
import I18n from '../../I18n/I18n'
import AppConfig from '../../Config/AppConfig'
import { logout } from '../../Services/ServerAccessService'
import GUIActions from '../../Redux/GUIRedux'
import BrandStrings from '../../Themes/BrandStrings'

const { Header, Sider, Content } = Layout
const { modules } = AppConfig

const modulesWithIcons = {
  home: <HomeOutlined />,
  app: <PlayCircleOutlined />,
  basics: <ToolOutlined />,
  coach: <UserOutlined />,
  colors: <FormatPainterOutlined />,
  onboarding: <PlaySquareOutlined />,
  screens: <ProfileOutlined />,
  mediaLibrary: <BookOutlined />,
  coaching: <MessageOutlined />,
  account: <SettingOutlined />,
  export: <ExportOutlined />,
  transfer: <CloudSyncOutlined />
}

// Styles
const globalLayoutStyle = {
  width: '100%',
  height: 'calc(100vh)'
}

const headerStyle = {
  color: '#2F2F2F',
  backgroundColor: '#f5f5f5',
  padding: 0
}

const headerText = {
  paddingLeft: 25,
  fontSize: 25
}

const logoutLogoStyle = { paddingRight: 5 }

const logoutTextStyle = {
  paddingRight: 25
}

const rightLayoutStyle = {
  backgroundColor: '#fff'
}

const contentWrapperStyle = { overflow: 'auto', height: '100vh' }

const contentStyle = {
  paddingLeft: 25,
  paddingRight: 25,
  paddingBottom: 25
}

function withHooks (WrappedComponent) {
  return function (props) {
    const app = App.useApp()

    return <WrappedComponent app={app} {...props} />
  }
}

class Dashboard extends Component {
  state = {
    collapsed: false
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    })
  }

  getContentParam = () => {
    const { match } = this.props

    // remove trailing slash if there is one..
    switch (this.props.location.pathname.replace(/\/$/, '')) {
      case `${match.path}/home`:
        return 'home'
      case `${match.path}/app`:
        return 'app'
      case `${match.path}/basics`:
        return 'basics'
      case `${match.path}/coach`:
        return 'coach'
      case `${match.path}/colors`:
        return 'colors'
      case `${match.path}/onboarding`:
        return 'onboarding'
      case `${match.path}/screens`:
        return 'screens'
      case `${match.path}/mediaLibrary`:
        return 'mediaLibrary'
      case `${match.path}/coaching`:
        return 'coaching'
      case `${match.path}/account`:
        return 'account'
      case `${match.path}/export`:
        return 'export'
      case `${match.path}/transfer`:
        return 'transfer'
      default:
        return 'home'
    }
  }

  renderHeader = () => {
    const content = this.getContentParam()

    return (
      <Header style={headerStyle}>
        <Row justify={'space-between'}>
          <span style={headerText}>
            {I18n.t('Dashboard.header', {
              branding: BrandStrings.general('customerName')
            })}
            &nbsp;&nbsp;–&nbsp;&nbsp;
            <strong>
              {modulesWithIcons[content]}
              &nbsp;&nbsp;
              {I18n.t('Navigation.' + content)}
            </strong>
          </span>
          <div style={logoutTextStyle}>
            <Link
              onClick={() => logout()}
              className='logoutButton'
              to={AppConfig.project.path + 'dashboard/login'}
            >
              <LogoutOutlined style={logoutLogoStyle} />
              <span>{I18n.t('Dashboard.logout')}</span>
            </Link>
          </div>
        </Row>
      </Header>
    )
  }

  render () {
    const { match, location, saved, setSaved, app } = this.props
    const { collapsed } = this.state

    // Use matchPath to check currently active route
    const testPath = matchPath(location.pathname, {
      path: `${match.path}/:activeRoute`,
      exact: false,
      strict: false
    })
    // if no subroute was given in path (e.g. just '.../dashboard'), use coach as default route (see routing setup)
    const activeRoute =
      testPath && testPath.params ? testPath.params.activeRoute : 'home'

    const logoStyle = {
      width: collapsed ? '50%' : '80%',
      height: 30,
      margin: 20,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundImage: `url(${collapsed ? logoIcon : logo})`
    }

    return (
      <Layout style={globalLayoutStyle}>
        <Sider
          collapsible
          collapsed={this.state.collapsed}
          className='side-menu'
          onCollapse={this.toggle}
        >
          <div style={logoStyle} />
          <Route
            render={({ history }) => (
              <Menu
                style={{ paddingBottom: 50 }}
                theme='dark'
                selectedKeys={[activeRoute]}
                items={Object.entries(modules).map(([key, value]) => {
                  if (value) {
                    const url = `${match.url}/${key}`
                    const handleClick = async (e) => {
                      e.preventDefault()

                      let navigate = true
                      if (!saved) {
                        navigate = await app.modal.confirm({
                          title: I18n.t('Dashboard.unsavedWarning.title'),
                          content: I18n.t('Dashboard.unsavedWarning.question'),
                          cancelText: I18n.t(
                            'Dashboard.unsavedWarning.cancelText'
                          ),
                          okText: I18n.t('Dashboard.unsavedWarning.yesText')
                        })
                      }

                      if (navigate) {
                        setSaved(true)

                        history.push(url)
                      }
                    }

                    return {
                      key,
                      label: (
                        <Link onClick={handleClick} to={''}>
                          {modulesWithIcons[key]}
                          <span>{I18n.t(`Navigation.${key}`)}</span>
                        </Link>
                      )
                    }
                  }
                  return null
                })}
              />
            )}
          />
        </Sider>
        <Layout style={rightLayoutStyle}>
          {this.renderHeader()}
          <div style={contentWrapperStyle}>
            <Content style={contentStyle}>
              <Switch>
                <Route
                  path={`${match.path}/home`}
                  render={(props) => {
                    return <Home />
                  }}
                />
                <Route
                  path={`${match.path}/app`}
                  render={(props) => {
                    return <AppContent />
                  }}
                />
                <Route
                  path={`${match.path}/basics`}
                  render={(props) => {
                    return <Basics />
                  }}
                />
                <Route
                  path={`${match.path}/coach`}
                  render={(props) => {
                    return <Coach />
                  }}
                />
                <Route
                  path={`${match.path}/colors`}
                  render={(props) => {
                    return <Colors />
                  }}
                />
                <Route
                  path={`${match.path}/onboarding`}
                  render={(props) => {
                    return <Onboarding />
                  }}
                />
                <Route
                  path={`${match.path}/screens`}
                  render={(props) => {
                    return <Screens />
                  }}
                />
                <Route
                  path={`${match.path}/mediaLibrary`}
                  render={(props) => {
                    return <MediaLibrary />
                  }}
                />
                <Route
                  path={`${match.path}/coaching`}
                  render={(props) => {
                    return <Coaching />
                  }}
                />
                <Route
                  path={`${match.path}/account`}
                  render={(props) => {
                    return <Account />
                  }}
                />
                <Route
                  path={`${match.path}/export`}
                  render={(props) => {
                    return <Export />
                  }}
                />
                <Route
                  path={`${match.path}/transfer`}
                  render={(props) => {
                    return <Transfer />
                  }}
                />
                {/* Default Path -> coach */}
                <Route
                  path={match.path}
                  render={(props) => {
                    return <Redirect to={`${match.path}/home`} />
                  }}
                />
              </Switch>
            </Content>
          </div>
        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  saved: state.guiState.saved
})

const mapStateToDispatch = (dispatch) => ({
  setSaved: (saved) => {
    dispatch(GUIActions.setSaved(saved))
  }
})

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(withHooks(Dashboard))
